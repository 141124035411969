
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, ProviderClient } from '@/services/Services';
import AddNewGarmentModelModal from '@/views/calculator/modals/addNewGarmentModelModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({})
export default class AddDocumentToCertification extends Vue {

    @Prop() providerIdentifier: string;
    @Prop() isMultiple: boolean;
    @Prop() sectionTitle: string;
    @Prop() certificationName: string;

    @Prop({
        default: () => []
    }) productModelsAlreadyUsed: string[];

    @Prop() callback: any;

    file: File = null;
    providerCertificationIdentifier: string = "";
    productModelId: string = "";
    model: OM.UploadCertificationVM = new OM.UploadCertificationVM();

    companyModelList: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.model.providerIdentifier = this.providerIdentifier;
        this.model.scoringTableCategoryTitle = this.sectionTitle;
        this.model.certificationName = this.certificationName;

        if(this.isMultiple) {
            CompanyClient.getModelsByCompany()
            .then(x => {
                this.companyModelList = x;

                this.productModelsAlreadyUsed.forEach(element => {
                    var foundIndex = this.companyModelList.findIndex(x => x.text == element);
                    if(foundIndex != -1)
                        this.companyModelList.splice(foundIndex, 1);
                });

                this.$nextTick(() => {
                    this.loaded = true;
                })
            })
        }
        else {
            this.$nextTick(() => {
                this.loaded = true;
            })
        }
    }

    get isSaveDisabled() {
        var base = !this.file || !this.model.expirationDate;
        if(this.isMultiple)
            return base || !this.productModelId;
        else
            return base;
    }

    addNewModel() {
        this.$opModal.show(AddNewGarmentModelModal, {
            saveCallback: ((modelName: string) => {
                CompanyClient.createNewGarmentModel(modelName)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = modelName;
                    this.companyModelList.push(newTextIdForList);
                    this.productModelId = x;
                    this.model.productName = modelName;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    save() {    
        if(this.isMultiple)
            this.model.productName = this.companyModelList.filter(x => x.identifier == this.productModelId)[0].text;        
        
        ProviderClient.uploadCertificationInfo(this.model)
        .then(x => {
            this.providerCertificationIdentifier = x;
            this.uploadFile();
        })
    }

    uploadFile() {
        ProviderClient.uploadCertificationFile(this.file, this.providerIdentifier, this.providerCertificationIdentifier)
        .then(x => {
            this.callback();
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: y ? y : this.$localizationService.getLocalizedValue("Something went wrong", "Something went wrong"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.callback();
                }
            })
        })
    }

}
