
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import AddWaterFootprintConfigurationModal from '../modals/addWaterFootprintConfigurationModal.vue';
import EditWaterFootprintConfigurationModal from '../modals/editWaterFootprintConfigurationModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class WaterFootprints extends Vue {

    @Prop() providerIdentifier: string;

    waterFootprintList: OM.WaterFootprintForMaterial[] = [];

    created() {
        this.init();
    }

    init() {
        ProviderClient.getProviderWaterFootprint(this.providerIdentifier)
        .then(x => {
            this.waterFootprintList = x;
        })
    }

    addNewOverrideConfiguration() {
        this.$opModal.show(AddWaterFootprintConfigurationModal, {
            providerIdentifier: this.providerIdentifier,
            notAvailableMaterials: this.waterFootprintList.map(x => x.rawMaterial),
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    editOverrideConfiguration(config: OM.WaterFootprintForMaterial) {
        this.$opModal.show(EditWaterFootprintConfigurationModal, {
            providerIdentifier: this.providerIdentifier,
            existingConfig: config,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
