
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ProductionPhaseConfigurationClient, ProviderClient, RawMaterialClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class EditWaterFootprintConfigurationModal extends Vue {

    @Prop() providerIdentifier: string;
    @Prop() callback: Function;

    @Prop({
        default: () => new OM.WaterFootprintForMaterial()
    }) existingConfig: OM.WaterFootprintForMaterial;

    model: OM.AddOrEditWaterFootprintOverrideVM = new OM.AddOrEditWaterFootprintOverrideVM();
    loaded: boolean = false;

    created() {
        this.model.providerIdentifier = this.providerIdentifier;
        this.model.rawMaterialIdentifier = this.existingConfig.rawMaterial.identifier;
        this.existingConfig.waterFootprints.forEach(element => {
            this.model.waterFootprints.push({...element});
        });

        this.$nextTick(() => {
            this.loaded = true;
        })
    }

    get isSaveDisabled() {
        return !this.model.rawMaterialIdentifier || this.model.waterFootprints.length == 0;
    }

    save() {
        ProviderClient.addOrEditWaterFootprintOverride(this.model)
        .then(x => {
            this.callback();
        })
    }

}
