
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ProductionPhaseConfigurationClient, ProviderClient, RawMaterialClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class AddWaterFootprintConfigurationModal extends Vue {

    @Prop() providerIdentifier: string;
    @Prop() callback: Function;

    @Prop({
        default: () => []
    }) notAvailableMaterials: OM.TextIdentifier[]

    availableRawMaterials: OM.TextIdentifier[] = [];

    model: OM.AddOrEditWaterFootprintOverrideVM = new OM.AddOrEditWaterFootprintOverrideVM();
    loaded: boolean = false;

    created() {
        this.model.providerIdentifier = this.providerIdentifier;

        RawMaterialClient.getAllConfigurations()
        .then(x => {
            var filtered = x.filter(y => this.notAvailableMaterials.findIndex(z => z.identifier == y.identifier) == -1);
            filtered.forEach(element => {
                var textIdEl = new OM.TextIdentifier();
                textIdEl.identifier = element.identifier;
                textIdEl.text = element.materialName;
                this.availableRawMaterials.push(textIdEl);
            });

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get isSaveDisabled() {
        return !this.model.rawMaterialIdentifier || this.model.waterFootprints.length == 0;
    }

    getDefaultConfiguration() {
        RawMaterialClient.getWaterFootprintsByMaterial(this.model.rawMaterialIdentifier)
        .then(x => {
            this.model.waterFootprints = x;
        })
    }

    save() {
        ProviderClient.addOrEditWaterFootprintOverride(this.model)
        .then(x => {
            this.callback();
        })
    }

}
