
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class ProductScoringModal extends Vue {

    @Prop() providerIdentifier: string;

    model: {[key: string]: OM.KeyValuePairOfStringAndString } = {};
    loaded: boolean = false;

    created() {
        ProviderClient.getProductScoringGrupedByProductModelNameForProvider(this.providerIdentifier)
        .then(x => {
            this.model = x;

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

}
