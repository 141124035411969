
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, ProviderClient } from '@/services/Services';
import EditGarmentManagementModal from '../modals/editGarmentManagementModal.vue';
import ProviderGarmentProductionsTable from '../components/providerGarmentProductionsTable.vue';
import DocsAndCertifications from '../components/docsAndCertifications.vue';
import WaterFootprints from '../components/waterFootprints.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProviderGarmentProductionsTable,
        DocsAndCertifications,
        WaterFootprints
    }
})
export default class EditProvider extends Vue {

    providerIdentifier: string = "";
    info: OM.ProviderInfoVM = new OM.ProviderInfoVM();
    model: OM.ProviderEditInfoVM = new OM.ProviderEditInfoVM();

    countries: OM.TextIdentifier[] = [];

    emptyGuid: string = "00000000-0000-0000-0000-000000000000";
    activeSection: number = 1;
    loaded: boolean = false;

    created() {
        this.providerIdentifier = this.$route.params.providerIdentifier.toString();
        if(this.providerIdentifier == '0')
            this.providerIdentifier = this.emptyGuid;

        var activeSectionFromUrl = this.$route.params.activeSection.toString();
        if(activeSectionFromUrl)
            this.activeSection = parseInt(activeSectionFromUrl);

        this.init();
    }

    init() {
        Promise.all([
            ProviderClient.getProviderInfo(this.providerIdentifier),
            ElectricityEmissionClient.getCountries(true)
        ])
        .then(xs => {
            this.info = xs[0];
            this.countries = xs[1];

            this.model = {...this.info.providerEditInfo};
            this.providerIdentifier = this.model.providerIdentifier;
            this.loaded = true;
        })
    }

    get saveDisabled() {
        return !this.model.providerName || !this.model.countryIdentifier;
    }

    save() {
        ProviderClient.edit(this.model)
        .then(x => {
            this.providerIdentifier = x;
            this.init();
        })
    }

    editGarmentManagement(index: number) {
        var model = new OM.EditGarmentProductionsVM();

        if(index > -1)
            model = this.info.garmentProductions[index];

        model.provider.identifier = this.providerIdentifier;
        this.$opModal.show(EditGarmentManagementModal, {
            isNew: index == -1,
            editGarmentProductions: model,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }
    deleteGarmentManagement(index: number) {
        if(confirm("Do you really want to delete?")) {
            ProviderClient.removeGarmentProductions(this.providerIdentifier, index)
            .then(x => {
                this.init();
            })
        }
    }

}
