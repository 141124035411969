
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import AddDocumentToCertification from '../modals/addDocumentToCertification.vue';
import * as OM from '@/Model';
import ProductScoringModal from '../modals/productScoringModal.vue';

@Options({
    components: {}
})
export default class DocsAndCertifications extends Vue {

    @Prop() providerIdentifier: string;

    certificationPageVM: OM.CertificationPageVM[] = [];

    created() {
        this.init();
    }

    init() {
        ProviderClient.getProviderCertifications(this.providerIdentifier)
        .then(x => {
            this.certificationPageVM = x;
        })
    }

    addDocument(isMultiple: boolean, groupName: string, certName: string) {
        var productModelsAlreadyUsed = [];

        if(isMultiple) {
            var group = this.certificationPageVM.filter(x => x.scoringTableCategoryTitle == groupName)[0];
            var uploadedCertificates = group.certificationSubCategories.filter(x => x.certificationName == certName);
            uploadedCertificates.forEach(element => {
                productModelsAlreadyUsed.push(element.productModel);
            });
        }

        this.$opModal.show(AddDocumentToCertification, {
            providerIdentifier: this.providerIdentifier,
            isMultiple: isMultiple,
            sectionTitle: groupName,
            certificationName: certName,
            productModelsAlreadyUsed: productModelsAlreadyUsed,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    openProductScoringDetailModal() {
        this.$opModal.show(ProductScoringModal, {
            providerIdentifier: this.providerIdentifier
        })
    }

}
